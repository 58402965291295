import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Reviewscriptform from "../../components/activecampaign/reviewscriptform/reviewscriptform"

const IndexPage = () => (
  <Layout>

    <SEO title="The Ultimate Guide To Getting Google Reviews - Five Star Review System" />

    <Reviewscriptform
      // industry='Dental'
      showPhone={true}
      phoneRequired={true}
      lb='review-script'
    />

  </Layout>
)

export default IndexPage
